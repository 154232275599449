@import "../../Styles/Colors/color_variables.scss";

.MaximumComponent {
  position: fixed;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
  height: 80%;
  width: 80%;
  background: "#ffffff";
}
.MainLayoutroot {
  display: flex;
}
.footer-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 10px;
}
.navigationPath {
  width: max-content;
  height: 24px;
  border-radius: 14px;
  margin-left: 19px;
  border: 1px solid #ebecf0;
  display: inline-block;
  margin-bottom: 15px;
  margin-top: 10px;
  div:nth-child(1) {
    padding-left: 7px;
  }
  div {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-auto-flow: column;
    p {
      margin: 0;
      font-family: "SF Pro Text", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #b3bac5;
    }
  }
}

.MinimumComponent {
  position: fixed;
  left: 80%;
  top: 15%;

  transform: translate(-50%, -50%);
  height: 130px;
  width: 200px;
  background: "#ffffff";
}
.CallStyleDOM {
  cursor: move;
  text-align: center;
}

.CallStyleDOM:not(.react-draggable-dragging) {
  -webkit-transition: -webkit-transform 0.5s ease-out; /* Safari */
  transition: transform 0.5s ease-out;
}

.GroupCallStyleDOM {
  cursor: move;
  text-align: center;
}

.GroupCallStyleDOM:not(.react-draggable-dragging) {
  -webkit-transition: -webkit-transform 0.5s ease-out; /* Safari */
  transition: transform 0.5s ease-out;
}

.headerprofileImage {
  height: 30px !important;
  width: 30px !important;
  border-radius: 50px !important;
}
.online-dot {
  position: absolute;
  width: 12px;
  height: 12px;
  left: 30px;
  top: 12px;
  background: #56c596;
  border-radius: 12px;
  border: 1px solid #f8f8f8;
}
.notification-dot {
  position: absolute;
  width: 16px;
  height: 16px;
  background: #e52727;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  left: 43.5%;
  right: -12.5%;
  top: 15.67%;
  bottom: 58.33%;
  border: 1px solid #f8f8f8;
}
.provider-type {
  font-family: PoppinsRegular, sans-serif !important;
  font-size: 0.75rem !important;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: $secondary-color-1;
}
.name {
  color: "#172B4D";
}
.signout-button {
  background: white !important;
  border: 2px solid var(--primary-color) !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  text-transform: initial !important;
  width: 82px !important;
  height: 40px !important;
  margin-right: 12px !important;
}
.continue-button {
  background: var(--primary-color) !important;
  border: 2px solid var(--primary-color) !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  text-transform: initial !important;
  height: 40px !important;
  padding: 8px 20px !important;
  color: #ffffff !important;
}
.logout-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logout-div1 {
  padding-top: 20px;
}
.padding-left {
  padding-left: 5px;
}
.backto-consult.padding-left:hover {
  color: $primary-color-2 !important;
}
.modal {
  position: absolute; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 50%;
  top: 50%;
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  opacity: 1;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.notificationBlink {
  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: blink 2s infinite;
  }
  img {
    &:first-child {
      animation-delay: 0.6666s;
    }
    &:nth-child(1) {
      animation-delay: 1.3333s;
    }
    &:last-child {
      animation-delay: 2s;
    }
  }
}
