@import "../../Styles/Colors/color_variables.scss";
.agoracall {
  .MuiFormControl-root {
    margin-top: 20px;
  }
  .MuiSelect-select.MuiSelect-select {
    padding-right: 90px;
  }
}
.remoteVideoScreen {
  height: 139px;
  width: 138px;
  border-radius: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  div {
    border-radius: 10px !important;
  }
}
#videoContainer video {
  left: 0;
  right: 0;
  p {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    height: 100px;
    width: 100px;
    display: grid;
    place-content: center;
    border: 1px solid black;
    background: #ffffff;
    border-radius: 50%;
    padding: 86px;
    font-size: 30px;
    margin: 0;
  }
}
.receiverName {
  text-align: center;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 84px;
  width: 84px;
  display: grid;
  place-content: center;
  border: 1px solid black;
  background: #ffffff;
  border-radius: 50%;
  margin: 0;
  .innerCircle {
    height: 78px;
    width: 78px;
    border-radius: 50%;
    border: 1px solid black;
    font-family: Poppins, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.20000000298023224px;
    text-align: center;
    color: var(--primary-color);
    display: grid;
    place-content: center;
    margin: 0;
  }
}
.receiverProfile {
  position: absolute;
  left: 50%;
  top: 65%;
  transform: translate(-50%, -50%);
  p {
    margin: 0;
  }
}
.receiverProfile > p:nth-child(1) {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: $primary-color-4;
  line-height: 20px;
  letter-spacing: -0.20000000298023224px;
}
.receiverProfile > p:nth-child(2) {
  font-size: 12px;
  font-style: normal;
  color: $primary-color-4;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
}
.subContainer video {
  left: 0;
  right: 0;
}

#auxiliary {
  position: absolute;
  bottom: 0;
  display: grid;
  height: 150px;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  /*grid-template-columns: 45% 10% 45%;*/
  /*grid-template-areas:"localStream cancel subcontainer";*/
}

#settings {
  position: absolute;
  width: auto;
  top: 10px;
  right: 10px;
}

.operation {
  width: 100%;
  position: absolute;
  grid-template-columns: repeat(4, 80px);
  bottom: 7px;
  display: none;
  justify-content: center;
  justify-items: center;
}

.audVidContainer:hover {
  .operation {
    display: grid !important;
  }
  .receiverNameVideo {
    display: block !important;
  }
  .timerSeconndaryVideo {
    display: block !important;
  }
}

@media (hover: none) and (pointer: coarse) {
  .operation {
    display: grid !important;
  }
  .receiverNameVideo {
    display: block !important;
  }
  .timerSeconndaryVideo {
    display: block !important;
  }
}

.MuiIcon-colorPrimary {
  color: "white";
}
.agora-secondary-button {
  /* position: absolute; */
  width: 64px;
  height: 72px;
  left: 568px;
  cursor: pointer;
  top: 646px;
  flex-direction: column;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: #e52727;
  opacity: 0.9;
  border-radius: 16px;
  .captions-default {
    color: #ffffff !important;
  }
}
.agora-primary-button {
  /* position: absolute; */
  width: 64px;
  height: 72px;
  left: 568px;
  top: 646px;
  display: flex;
  cursor: pointer;
  justify-content: space-evenly;
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  flex-direction: column;
  opacity: 0.9;
  border-radius: 16px;
  .captions-default {
    color: #ffffff !important;
  }
}
.agora-primary-button-video {
  /* position: absolute; */
  width: 64px;
  height: 72px;
  left: 568px;
  top: 646px;
  display: flex;
  cursor: pointer;
  justify-content: space-evenly;
  align-items: center;
  background: #172b4d;
  flex-direction: column;
  opacity: 0.9;
  border-radius: 16px;
  .captions-default {
    color: #ffffff !important;
  }
}
.audVidContainer {
  height: 100%;
  width: 100%;
  border: 1px solid #ffffff;
  box-shadow: 0 12px 34px 0 rgba(57, 67, 87, 0.1);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  position: relative;
  //  .agora_video_player{
  //    position: relative !important;
  //  }
}
.icons {
  cursor: pointer;
}
.call_end {
  text-align: center;
  font-size: 15px;
  color: #ffffff;
  margin: 0;
}
//  .bgcolor{
//  }
.connecting_status {
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  position: absolute;
  top: 73%;
  left: 41%;
  p {
    margin: 0;
  }
  div {
    position: absolute;
    left: 100%;
    top: 5px;
  }
}
.connecting_status_group {
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  position: absolute;
  top: 73%;
  left: 50%;
  transform: translateX(-80px);
  p {
    margin: 0;
  }
  div {
    position: absolute;
    left: 100%;
    top: 5px;
  }
}
@media (hover: none) and (pointer: coarse) {
  .connecting_status_group {
    top: initial;
    bottom: 100px;
  }
}
.header-h7-light {
  font-family: Poppins, sans-serif;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-22 !important;
}
.content-emphasized-sidenav-text {
  font-family: Poppins, sans-serif;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
  color: $secondary-color-8 !important;
}
.receiverNameVideo {
  padding: 5px 15px 15px 13px;
  margin-top: 5px;
  display: none;
  text-align: start;
  flex-direction: column;
  position: absolute;
  left: 0px;
  top: 5px;
  background: rgba(23, 43, 77, 0.6);
  border-radius: 0px 10px 10px 0px;
}
.remoteVideoScreenBorder {
  border-radius: 0px 10px 10px 0px;
}

.receiverNameVideo > p:nth-child(1) {
  margin: 0px 0px 3px 0px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: -0.2px;
  color: #ffffff;
}
.receiverNameVideo > p:nth-child(2) {
  margin: 4px 0px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.2px;
  color: #ffffff;
}
.timerAudio {
  position: absolute;
  top: 37%;
  margin: 0;
  left: 49%;
  transform: translate(-34%, -50%);
}
.timerVideo {
  position: absolute;
  top: 10;
  left: 25px;
  z-index: 100;
}
.timerSeconndaryVideo {
  display: none;
}
.videoInitiator {
  background: $primary-color-1 !important;
  height: 100%;
  width: 100%;
  margin-top: 1px;
  border-radius: 10px 10px 10px 10px;
  display: grid;
  place-content: center;
  p {
    margin: 0;
  }
  p:nth-child(1) {
    color: $primary-color-2;
    width: 52px;
    font-family: Poppins, sans-serif;
    height: 52px;
    border-radius: 50%;
    place-self: center;
    background: white;
    display: grid;
    place-content: center;
  }
  p:nth-child(2) {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    color: $primary-color-4;
  }
  p:nth-child(3) {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: $primary-color-4;
  }
  p:nth-child(4) {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: $primary-color-4;
  }
}
.audio_call_snackbar {
  div {
    div {
      min-width: 0px;
      background: $primary-color-1;
    }
  }
}
.snackbarAvator {
  margin-top: 24px;
  border-radius: 50%;
  background: radial-gradient(
    55.09% 55.09% at 50% 50%,
    rgba(239, 241, 245, 0.5) 0%,
    rgba(239, 241, 245, 0) 100%
  ) !important;
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.snackbarSecondaryAvator {
  border-radius: 50%;
  background: radial-gradient(
    60.12% 60.12% at 50% 50%,
    rgba(239, 241, 245, 0.7) 0%,
    rgba(239, 241, 245, 0) 100%
  ) !important;
  height: 62.22px;
  width: 62.22px;
  display: flex;
  justify-content: center;
}
.lds-ellipsis {
  display: inline-block;
  width: 80px;
  height: 80px;
  .firstDot {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 150px;
    border-radius: 50%;
    background: #ffffff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
@media only screen and (max-width: 1150px) {
  .timerAudio {
    top: 18px;
    left: 48px;
    z-index: 100;
  }
  .receiverName {
    top: 32%;
    height: 52px;
    width: 52px;
    .innerCircle {
      border: none !important;
      font-size: 18px;
    }
  }
  .receiverProfile {
    left: 51%;
    top: 48%;
  }
}
